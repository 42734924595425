import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus, JournallingPeriod, RepeatPeriod, ScheduleRepeatPeriod } from '../../../enum';
import { environment } from '../../../environment/environment';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import type { AdminClinician } from '../admin-clinicians/adminClinicianSlice';
import { fetchClientProfileInfo } from '../client-profile/clientProfileSlice';
import { isMKI } from '../Login/login.helper';

dayjs.extend(utc);
dayjs.extend(timezone);

const { logError } = RaygunErrorHandlerService();

export class Reminder {
  reminder_group_id: string | null = null;
  client_id: string | null = null;
  product_id: string | null = null;
  repeat_period: RepeatPeriod | null = RepeatPeriod.NoReminder;
  repeat_every: number = 1;
  start_date: string | null = null;
  hours_of_day: number[] = [];
  days_of_week: number[] | undefined = undefined;
  days_of_month: number[] | undefined = undefined;
  title: string | null = null;
  is_delete?: boolean;
}

export class ScheduledProduct {
  id: string | null = null;
  client_id: string | null = null;
  product_id: string | null = null;
  repeat_interval: ScheduleRepeatPeriod = ScheduleRepeatPeriod.Day;
  repeat_every: number = 1;
  start_date: string = dayjs().format();
  journal_interval: JournallingPeriod = JournallingPeriod.ThreeDays;
  first_reminder_hour: number | null = 9;
  last_reminder_hour: number | null = 17;
}

export class ProductAssignment {
  id: string | null = null;
  client_id: string | null = null;
  product_id: string | null = null;
  timezone_name: string | null = dayjs.tz.guess();
  always_available: boolean | null = null;
  reminder: Reminder | null = new Reminder();
  scheduled_product: ScheduledProduct | null = new ScheduledProduct();
  is_dirty?: boolean = false;
  is_delete?: boolean = false;
}

export class Client {
  address: string | null = null;
  city: string | null = null;
  country: string | null = null;
  date_of_birth: Dayjs | null = null;
  email: string | null = null;
  first_name: string | null = null;
  gender: string | null = null;
  last_name: string | null = null;
  marital_status: string | null = null;
  middle_name: string | null = null;
  mobile_phone: string | null = null;
  race: string | null = null;
  state: string | null = null;
  zip_code: string | null = null;
  clinician_ids: string[] = [];
  site_ids: string[] = [];
  created: string | null = null;
  medical_record_number: string | null = null;
  temp_password: string | null = null;
  organization: string | null = null;
  organization_id: string | null = null;
  account_status: string | null = null;
  home_phone: string | null = null;
  id: string | null = null;
  product_assignments: ProductAssignment[] = [];
  expected_delivery_date: Dayjs | null = null;
  actual_delivery_date: Dayjs | null = null;
  study_id: string | null = null;
  secondary_phone: string | null = null;
  residential_area: string | null = null;
  home_clinic: string | null = null;
  diagnosis_id_1: string | null = null;
  other_diagnosis_text_1: string | null = null;
  diagnosis_id_2: string | null = null;
  other_diagnosis_text_2: string | null = null;
  insurance_carrier_id: string | null = null;
  client_program_id: string | null = null;
  client_type_id: string | null = null;
  has_submission: boolean | null = null;
}

export type ClientProduct = {
  assigned: boolean;
  product_id: string;
  organization_id: string;
  name: string;
  order: number;
  short_name: string;
};

export type Diagnosis = {
  organization_id: string;
  diagnosis_id: string;
  diagnosis_name: string;
  assigned: boolean;
  diagnosis_order: number;
  order: number;
};

type InsuranceCarrier = {
  organization_id: string;
  insurance_carrier_id: string;
  insurance_carrier_name: string;
  insurance_carrier_order: number;
};

type ClientProgram = {
  organization_id: string;
  client_program_id: string;
  client_program: string;
  client_program_order: number;
};

type ClientType = {
  organization_id: string;
  client_type_id: string;
  client_type: string;
  client_type_order: number;
};

class ClientUpsertResponse {
  client_id: string | null = null;
  is_update: boolean = false;
  no_devices_registered: boolean = true;
}

export type ClientUpsertSliceType = {
  client: Client;
  diagnoses: Diagnosis[];
  clinicians: AdminClinician[];
  products: ClientProduct[];
  insuranceCarriers: InsuranceCarrier[];
  clientPrograms: ClientProgram[];
  clientTypes: ClientType[];
  disabledClientUpsertForm: boolean;
  upsertClientApiStatus: APIStatus;
  confirmEmail: boolean;
  successModal: boolean;
  clientUpsertResponse: ClientUpsertResponse;
  individualClientApiStatus: APIStatus;
  randomClientStatus: boolean;
};

const initialState: ClientUpsertSliceType = {
  client: new Client(),
  insuranceCarriers: [],
  clientPrograms: [],
  clientTypes: [],
  diagnoses: [],
  clinicians: [],
  products: [],
  individualClientApiStatus: APIStatus.IDLE,
  upsertClientApiStatus: APIStatus.IDLE,
  disabledClientUpsertForm: true,
  confirmEmail: false,
  successModal: false,
  clientUpsertResponse: new ClientUpsertResponse(),
  randomClientStatus: !environment.environment_deploy.includes('prod'),
};

export const fetchIndividualClient = createAsyncThunk<Client, string, AsyncThunkConfig>(
  'clientUpsert/fetchIndividualClient',
  async (client_id, thunkAPI) => {
    try {
      const response = await axios.post('v3_client_settings', { client_id });
      return (response as unknown as Client) || new Client();
    } catch (e) {
      logError(e, ['clientUpsertSlice', 'fetchIndividualClient']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

type ClientEndpointData = { upsertClient: Client; endpoint: string };

export const saveUpsertClient = createAsyncThunk<ClientUpsertResponse, ClientEndpointData, AsyncThunkConfig>(
  'clientUpsert/saveUpsertClient',
  async (clientData, thunkAPI) => {
    try {
      const { upsertClient, endpoint } = clientData;
      const { organization_id } = thunkAPI.getState().authSlice.currentUser;
      const url = organization_id && isMKI(organization_id) ? 'v2_admin_mki_client_upsert' : endpoint;
      const response = (await axios.post(url, upsertClient)) as ClientUpsertResponse;
      const { client_id, is_update } = response;
      if (response && client_id) {
        thunkAPI.dispatch(setClientUpsertResponse(response));
        thunkAPI.dispatch(fetchIndividualClient(client_id));
        if (is_update) {
          thunkAPI.dispatch(fetchClientProfileInfo(client_id));
          thunkAPI.dispatch(disableClientSettingsForm(true));
          notification.destroy();
          notification.success({
            message: 'Update successful',
            description: 'Edited information has been saved.',
            style: {
              border: '1px solid #52c41a',
            },
          });
        } else {
          thunkAPI.dispatch(showSuccessModal());
        }
      }
      return response;
    } catch (e) {
      logError(e, ['clientUpsertSlice', 'saveUpsertClient']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchAllDiagnosis = createAsyncThunk<Diagnosis[], undefined, AsyncThunkConfig>(
  'clientUpsert/fetchAllDiagnosis',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get('v2_diagnosis');
      return (response as unknown as Diagnosis[]) ?? [];
    } catch (e) {
      logError(e, ['clientUpsertSlice', 'fetchAllDiagnosis']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchAllInsuranceCarriers = createAsyncThunk<InsuranceCarrier[], undefined, AsyncThunkConfig>(
  'clientUpsert/fetchAllInsuranceCarriers',
  async (_, thunkAPI) => {
    try {
      const response = await axios.post('v2_insurance_carriers');
      return response as unknown as InsuranceCarrier[];
    } catch (e) {
      logError(e, ['clientUpsertSlice', 'fetchAllInsuranceCarriers']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchAllClientPrograms = createAsyncThunk<ClientProgram[], undefined, AsyncThunkConfig>(
  'clientUpsert/fetchAllClientPrograms',
  async (_, thunkAPI) => {
    try {
      const response = await axios.post('v2_client_program');
      return response as unknown as ClientProgram[];
    } catch (e) {
      logError(e, ['clientUpsertSlice', 'fetchAllClientPrograms']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchAllClientTypes = createAsyncThunk<ClientType[], undefined, AsyncThunkConfig>(
  'clientUpsert/fetchAllClientTypes',
  async (_, thunkAPI) => {
    try {
      const response = await axios.post('v2_client_type');
      return response as unknown as ClientType[];
    } catch (e) {
      logError(e, ['clientUpsertSlice', 'fetchAllClientTypes']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchAllClinicians = createAsyncThunk<AdminClinician[], undefined, AsyncThunkConfig>(
  'clientUpsert/fetchAllClinicians',
  async (_, thunkAPI) => {
    try {
      const response = await axios.post('v2_admin_clinician');
      return response as unknown as AdminClinician[];
    } catch (e) {
      logError(e, ['clientUpsertSlice', 'fetchAllClinicians']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchAllProducts = createAsyncThunk<ClientProduct[], string, AsyncThunkConfig>(
  'clientUpsert/fetchAllProducts',
  async (organization_id, thunkAPI) => {
    try {
      const response = await axios.post('v3_get_organization_product', { organization_id });
      return response as unknown as ClientProduct[];
    } catch (e) {
      logError(e, ['clientUpsertSlice', 'fetchAllProducts']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const clientUpsertSlice = createSlice({
  name: 'clientUpsert',
  initialState,
  reducers: {
    setNewClient: (state) => {
      state.client = new Client();
    },
    disableClientSettingsForm: (state, action: PayloadAction<boolean>) => {
      state.disabledClientUpsertForm = action.payload;
    },
    showConfirmEmailModal: (state) => {
      state.confirmEmail = !state.confirmEmail;
    },
    showSuccessModal: (state) => {
      state.successModal = !state.successModal;
    },
    setClientUpsertResponse: (state, action: PayloadAction<ClientUpsertResponse>) => {
      state.clientUpsertResponse = action.payload;
    },
    resetProducts: (state) => {
      state.products = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveUpsertClient.pending, (state, _action) => {
        state.upsertClientApiStatus = APIStatus.PENDING;
      })
      .addCase(saveUpsertClient.fulfilled, (state, _action) => {
        state.upsertClientApiStatus = APIStatus.FULFILLED;
      })

      .addCase(saveUpsertClient.rejected, (state, _action) => {
        state.upsertClientApiStatus = APIStatus.ERROR;
      })
      .addCase(fetchIndividualClient.pending, (state, _action) => {
        state.individualClientApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchIndividualClient.fulfilled, (state, action) => {
        state.client = action.payload;
        state.individualClientApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAllDiagnosis.fulfilled, (state, action) => {
        state.diagnoses = action.payload;
      })
      .addCase(fetchAllClinicians.fulfilled, (state, action) => {
        state.clinicians = action.payload;
      })
      .addCase(fetchAllInsuranceCarriers.fulfilled, (state, action) => {
        state.insuranceCarriers = action.payload;
      })
      .addCase(fetchAllClientPrograms.fulfilled, (state, action) => {
        state.clientPrograms = action.payload;
      })
      .addCase(fetchAllClientTypes.fulfilled, (state, action) => {
        state.clientTypes = action.payload;
      })
      .addCase(fetchAllProducts.fulfilled, (state, action) => {
        state.products = action.payload;
      });
  },
});

export const {
  disableClientSettingsForm,
  setNewClient,
  showConfirmEmailModal,
  showSuccessModal,
  setClientUpsertResponse,
  resetProducts,
} = clientUpsertSlice.actions;
